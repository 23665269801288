.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 20px 10px 20px;
  }
  .analysis-container {
    box-shadow: 0px 0px 10px 0px lightgrey;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    
  }
  .card-row {
    display: flex;
  }
  
  .base-card{
    border-radius: 10px;
    padding: 10px;
    /* margin-right: 10px; */
    font-weight: 600;
    width: 100%;
    font-size: 11px;
  }
  .card-green{
    background-color: #63D02C60;
      color: #38920A;
  }
  .card-orange{
    background-color: #FFD6B1;
      color: #FF7B00;
  }
  .card-red{
    background-color: #FFC2C2;
    color: #F83030;
  }
  .myRow {
    display: flex;
    align-items: center;
  }
  
  .badge {
    background-color: white;
    padding: 5px;
    border-radius: 20px;
    margin-right: 20px;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
  .card-description{
    /* margin-top: 20px;
    margin-bottom:10px; */
    margin-left: 20px;
  }
  .fix100{
    min-width: 100px;
    max-width: 100px;
    width: 100px;
  }