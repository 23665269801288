.activeStatus{
    background: green;
    color: white;
    padding: 5px;
    max-width: min-content;
    border-radius: 30px;
    font-size: 10px;
}
.inactiveStatus{
    background: red;
        color: white;
        padding: 5px;
        max-width: min-content;
        border-radius: 30px;
        font-size: 10px;
}
.MuiBox-root {
    padding: 0px;
}