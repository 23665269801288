.row2 {
    /* margin-top: 40px; */
    /* display: flex; */
    /* justify-content: center; */
   
}

.cards{
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: white !important;
    opacity: 1;
}