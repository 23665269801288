/* #### Generated By: http://www.cufonfonts.com #### */

/* @font-face {
    font-family: 'TT Commons Regular';
    font-style: normal;
    font-weight: normal;
    src: local('TT Commons Regular'), url('../../../src/assets/fonts/TTCommonsRegular.woff') format('woff');
    } */
    
/* 


    /* Fonts
-------------------------------- */
@font-face {font-family: 'TTCommons-Regular';src: url('../../../src/assets/fonts/tt-commons/3913DB_0_0.eot');src: url('../../../src/assets/fonts/tt-commons/3913DB_0_0.eot?#iefix') format('embedded-opentype'),url('../../../src/assets/fonts/tt-commons/3913DB_0_0.woff2') format('woff2'),url('../../../src/assets/fonts/tt-commons/3913DB_0_0.woff') format('woff'),url('../../../src/assets/fonts/tt-commons/3913DB_0_0.ttf') format('truetype'); font-display: fallback;}
@font-face {font-family: 'TTCommons-Bold';src: url('../../../src/assets/fonts/tt-commons/3913DB_B_0.eot');src: url('../../../src/assets/fonts/tt-commons/3913DB_B_0.eot?#iefix') format('embedded-opentype'),url('../../../src/assets/fonts/tt-commons/3913DB_B_0.woff2') format('woff2'),url('../../../src/assets/fonts/tt-commons/3913DB_B_0.woff') format('woff'),url('../../../src/assets/fonts/tt-commons/3913DB_B_0.ttf') format('truetype'); font-display: fallback;}
@font-face {font-family: 'TTCommons-DemiBoldItalic';src: url('../../../src/assets/fonts/tt-commons/3913DB_C_0.eot');src: url('../../../src/assets/fonts/tt-commons/3913DB_C_0.eot?#iefix') format('embedded-opentype'),url('../../../src/assets/fonts/tt-commons/3913DB_C_0.woff2') format('woff2'),url('../../../src/assets/fonts/tt-commons/3913DB_C_0.woff') format('woff'),url('../../../src/assets/fonts/tt-commons/3913DB_C_0.ttf') format('truetype'); font-display: fallback;}
@font-face {font-family: 'TTCommons-DemiBold';src: url('../../../src/assets/fonts/tt-commons/3913DB_D_0.eot');src: url('../../../src/assets/fonts/tt-commons/3913DB_D_0.eot?#iefix') format('embedded-opentype'),url('../../../src/assets/fonts/tt-commons/3913DB_D_0.woff2') format('woff2'),url('../../../src/assets/fonts/tt-commons/3913DB_D_0.woff') format('woff'),url('../../../src/assets/fonts/tt-commons/3913DB_D_0.ttf') format('truetype'); font-display: fallback;}
